<template>
  <base-label :class="labelClass" :for="name">
    {{ label }}
    <p v-if="error" class="text-error text-xs font-medium">{{ error }}</p>
    <input
      ref="input"
      :type="type"
      :name="name"
      :value="modelValue"
      @input="
        $emit(
          'update:modelValue',
          type === 'number'
            ? parseInt($event.target.value)
            : $event.target.value
        )
      "
      v-bind="$attrs"
      :placeholder="errorMessage || placeholder"
      :autocomplete="autocomplete ? 'on' : 'off'"
      class="block p-3 w-full bg-white border rounded"
      :class="{
        'mt-2 sm:mt-4': label,
        'border-red-600 placeholder-red-600': hasErrors && dirty,
        empty: modelValue == undefined || modelValue == '',
      }"
      :max="max || ''"
    />
    <span
      v-if="hasErrors && (type === 'date' || modelValue)"
      class="text-red-600 text-xs"
    >
      {{ errorMessage }}
    </span>
  </base-label>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,

  props: {
    error: String,
    autocomplete: Boolean,
    modelValue: [String, Number],
    label: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    type: {
      type: String,
      default: 'text',
      // Only allow types that essentially just render text boxes.
      validator(value) {
        return [
          'date',
          'email',
          'number',
          'password',
          'search',
          'tel',
          'text',
          'url',
        ].includes(value)
      },
    },
    validation: { type: Object, default: () => {} },
    max: {
      type: String,
      required: false,
    },
    min: {
      type: String,
      required: false,
    },
  },

  computed: {
    name() {
      return this.label?.toLowerCase()
    },
    hasErrors() {
      return !!this.validation?.$errors?.length
    },
    errorMessage() {
      return this.validation?.$errors?.[0]?.$message
    },
    dirty() {
      return this.validation?.$dirty
    },
  },
}
</script>

<style scoped lang="postcss">
input[type='date'] {
  @apply h-12 items-center justify-start;
}

@supports (-webkit-touch-callout: none) {
  input[type='date'] {
    -webkit-min-logical-width: calc(100% - 16px);
    @apply flex;
  }

  input::-webkit-date-and-time-value {
    @apply text-left;
  }

  input[type='date'].empty:not(:focus)::before {
    content: attr(placeholder);
    @apply text-secondary2 pointer-events-none absolute;
  }
}
</style>
