<template>
  <button
    @click="goTo"
    class="text-base transform rounded-md"
    :class="{
      primary: !secondary,
      secondary,
      primarySoft,
      graySoft,
      flat,
      round,
      redOutline,
      outline,
      'outline-1': outline,
      'p-4 sm:py-3 md:py-4': !round,
      '!p-2 text-sm': tight,
      'sm:text-2xl': !smallText,
      'font-semibold': semibold,
      'font-normal': !semibold,
      smallBrownOutline,
      smallBrown,
      greenOutline,
      green,
      rounder,
    }"
    v-bind="$attrs"
  >
    <div
      class="flex items-center justify-center"
      :class="{
        'py-0 px-1': smallBrownOutline || smallBrown,
      }"
    >
      <base-icon
        v-if="icon && !icon.rightHandSide"
        :component="icon.component"
        :name="icon.name"
        :source="icon.source"
        :class="icon.htmlClass"
      />

      <slot
        :class="{
          '!ml-3': !!icon && !icon.rightHandSide,
          '!mr-3': !!icon && icon.rightHandSide,
        }"
      />
      <base-icon
        v-if="icon && icon.rightHandSide"
        :component="icon.component"
        :name="icon.name"
        :source="icon.source"
        :class="icon.htmlClass"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import { Component } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    to?: RouteLocationRaw
    href?: string
    icon?: {
      rightHandSide?: boolean
      name: string
      source: any
      htmlClass: string
      component: Component
    }
    // Variants
    primary?: boolean
    secondary?: boolean
    primarySoft?: boolean
    graySoft?: boolean
    flat?: boolean
    round?: boolean
    tight?: boolean
    smallText?: boolean
    semibold?: boolean
    redOutline?: boolean
    outline?: boolean
    smallBrownOutline?: boolean
    smallBrown?: boolean
    greenOutline?: boolean
    green?: boolean
    rounder?: boolean
  }>(),
  {
    smallText: false,
    semibold: true,
  }
)

const router = useRouter()

const goTo = () => {
  if (props?.href) window.open(props.href, '_blank')
  if (props?.to) router.push(props.to)
}
</script>

<style lang="postcss" scoped>
.primary {
  @apply text-white bg-primary active:bg-primary-disabled;
}
.primary:disabled {
  @apply text-white bg-primary-disabled cursor-default;
}
.secondary {
  @apply text-primary active:bg-gray-200 bg-white;
}
.graySoft {
  @apply text-gray-800 bg-gray-200;
}
.secondary:disabled {
  @apply text-gray-600 bg-white border-gray-600 cursor-default;
}
.primarySoft {
  @apply text-primary bg-primary-light;
}
.round {
  @apply rounded-full;
}
.rounder {
  @apply rounded-xl;
}
.flat,
.flat:disabled {
  @apply px-0 text-black bg-transparent;
}
.redOutline {
  @apply text-error bg-transparent border !border-error active:bg-red-50;
}

.redOutline:disabled {
  @apply text-red-400 bg-red-50 border !border-error active:bg-red-50;
}

.smallBrownOutline {
  @apply text-brown-dark bg-transparent border border-brown-dark font-medium active:bg-brown-dark active:text-white py-1;
}

.smallBrown {
  @apply border border-brown-dark font-medium bg-brown-dark text-white;
}

.greenOutline {
  @apply text-green bg-transparent border  border-green  active:bg-green-900 active:text-white;
}

.green {
  @apply border-green bg-green;
}
</style>
