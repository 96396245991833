import Repository from './repository'
import type Booking from '@/types/api/types/Booking'

export default {
  getBookings() {
    return Repository.get(`/v1/user/bookings`)
  },
  getBooking({ bookingNo }: { bookingNo: string }) {
    return Repository.get(`/v1/user/bookings/${bookingNo}`)
  },
  getPublicBooking({ bookingNo }: { bookingNo: string }) {
    return Repository.get<Booking>(`/v2/bookings/${bookingNo}`)
  },
}
