<template>
  <base-label :class="labelClass" :for="name">
    {{ label }}
    <select
      :name="name"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs"
      class="block p-3 w-full h-12 bg-white border rounded"
      :class="{ 'text-gray-400': modelValue === null }"
    >
      <option :disabled="!allowEmpty" value>
        {{ placeholder }}
      </option>
      <option
        v-for="(value, key) in optionsObject"
        :value="key"
        :key="key"
        :selected="modelValue == key"
      >
        {{ value }}
      </option>
    </select>
  </base-label>
</template>

<script lang="ts" setup>
import humanize from '@/utils/humanize'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: number | string
    allowEmpty: boolean
    placeholder: string
    label?: string
    labelClass?: string | string[]
    options: string[] | object
  }>(),
  {
    allowEmpty: false,
    placeholder: 'select',
  }
)

const name = computed(() => {
  return props.label?.toLowerCase()
})

const optionsObject = computed(() => {
  // If options is not an array, just return it
  if (!Array.isArray(props.options)) return props.options

  // Otherwise, convert it to an Object using index as keys
  return Object.fromEntries(props.options.map(el => [el, humanize(el)]))
})
</script>
