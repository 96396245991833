<template>
  <label class="switch">
    <input type="checkbox" @change="update" :checked="checked" />
    <span class="slider round"></span>
  </label>
</template>

<script lang="ts" setup>
const checked = defineModel<boolean>()

const update = event => {
  checked.value = event.target.checked
}
</script>

<style lang="postcss" scoped>
.switch {
  @apply relative inline-block w-16 h-8;
}

/* Hide default HTML checkbox */
.switch input {
  @apply opacity-0 w-0 h-0;
}

/* The slider */
.slider {
  @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-300 transition-all duration-300;
}

.slider:before {
  @apply absolute content-[''] h-6 w-6 left-1 bottom-1 bg-white transition-all duration-300;
}

input:checked + .slider {
  @apply bg-green;
}

input:focus + .slider {
  @apply shadow-sm;
}

input:checked + .slider:before {
  @apply transform translate-x-8 shadow-lg;
}

/* Rounded sliders */
.slider.round {
  @apply rounded-full;
}

.slider.round:before {
  @apply rounded-full;
}
</style>
